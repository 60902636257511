<template>
  <div v-loading="loading" class="refund-detail-container">
    <div class="backBtn">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont" v-if="orderDetail">
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：<span class="detail-span1">{{ trade_status(orderDetail.trade_status) }}</span>
            </td>
          </tr>
          <!-- 下单人信息 -->
          <tr>
            <td>下单人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.member_name }}</td>
            <td>下单人手机号：{{ allData.mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人所属分组：</td>
            <td v-if="[5, 7].includes(orderDetail.mall_type)">下单人账号：{{ orderDetail.login_account || '' }}</td>
          </tr>
          <!-- 订单信息 -->
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>用户支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户支付方式：<span v-if="orderDetail.trade_kind == 3">商品售后换新</span>
              <span v-else-if="orderDetail.trade_kind == 2">售后更换商品</span>
              <span v-else-if="orderDetail.trade_kind == 4">补单</span>
              <span v-else>{{ orderDetail.payment_method_name }}</span>
            </td>
            <td>会员支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>商城下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>商城支付时间：{{ orderDetail.create_time | unixToDate }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>商城支付方式：站点账户余额</td>
            <td>
              商城支付金额：{{ orderDetail.enterprise_total_price | unitPrice("¥") }}
              <span>(商品：{{ orderDetail.enterprise_goods_price | unitPrice("¥") }} + 企业运费：{{
    orderDetail.enterprise_freight_price | unitPrice("¥") }})</span>
            </td>
          </tr>
          <tr class="detail-tr1">
            <td v-if="allData.source === '套餐卡商城'">卡号：{{ allData.trade_do.card_code }}</td>
            <td v-else>
              用户订单总价：{{ orderDetail.shop_goods_price + orderDetail.shop_freight_price | unitPrice("¥") }}
              <span>(商品：{{ orderDetail.shop_goods_price | unitPrice("¥") }} + 站点运费：{{ orderDetail.shop_freight_price |
    unitPrice("¥") }})</span>
            </td>
            <td>
              <span v-if="orderDetail.platform_total_price">
                企业支付金额：{{ orderDetail.platform_total_price | unitPrice("¥") }}
                <span>(商品：{{ orderDetail.platform_price | unitPrice("¥") }} + 站点运费：{{ orderDetail.platform_freight_price
    |
    unitPrice("¥") }})</span>
              </span>
              <span v-else>
                企业支付金额：{{ orderDetail.platform_price | unitPrice("¥") }} <span>(历史订单)</span>
              </span>

            </td>
          </tr>
          <tr class="detail-tr1">


          </tr>
          <!--        <tr class="detail-tr1">-->
          <!--          <td>-->
          <!--            平台支付金额：{{ orderDetail.total_price | unitPrice("¥") }}-->
          <!--          </td>-->
          <!--          <td>平台支付时间：{{ orderDetail.payment_time | unixToDate }}</td>-->
          <!--        </tr>-->
          <!-- 收货人信息 -->
          <tr>
            <td>收货人信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：
              {{ orderDetail.consignee_province }}{{ orderDetail.consignee_city }}{{
    orderDetail.consignee_county
  }}{{ orderDetail.consignee_town }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <tr v-if="allData.source === '套餐卡商城'">
            <td>备注信息</td>
          </tr>
          <tr v-if="allData.source === '套餐卡商城'" class="detail-tr1" style="display:inline-block; max-width: 30vw">
            <td v-if="allData.refund.remark">
              {{ allData.refund.remark }}
            </td>
            <td v-else>无</td>
          </tr>
          <tr>
            <td>订单来源信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>来源应用：
              <span v-if="orderDetail.mall_type == 2">现金商城</span>
              <span v-else-if="orderDetail.mall_type == 3">套餐卡商城</span>
              <span v-else-if="orderDetail.mall_type == 4">AI智能套餐卡商城</span>
              <span v-else-if="orderDetail.mall_type == 5">双选商城</span>
              <span v-else-if="orderDetail.mall_type == 7">一件代发商城</span>
              <span v-else-if="orderDetail.mall_type == 6">小程序商城</span>
            </td>
            <td>商城名称：{{ refund.shop_name }}</td>
          </tr>
          <tr v-if="orderDetail.shop_combo_name">
            <td>礼包信息</td>
          </tr>
          <tr v-if="orderDetail.shop_combo_name" class="detail-tr1">
            <td>礼包名称：{{ orderDetail.shop_combo_name }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4>商品信息</h4>
      <template>
        <el-table :data="tableData" :header-cell-style="{ background: '#cccccc' }">
          <el-table-column prop="goods_image" label="商品详情" width="300">
            <template slot-scope="scope">
              <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                <img :src="scope.row.goods_image" class="goods-image" alt="" />
                <el-tooltip class="item" effect="light" :content="scope.row.goods_name" placement="top">
                  <el-button style="max-width: 19vw; overflow: hidden; text-overflow: ellipsis;" class="shop-name"
                    type="text">{{ scope.row.goods_name }}
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="return_num" label="数量" />
          <el-table-column prop="price" label="平台售价" />
          <el-table-column prop="shop_enterprise_price" label="企业售价" />
          <el-table-column prop="shop_price" label="站点售价" />
          <el-table-column label="物流状态">
            <template slot-scope="scope">
              {{ state || 0 === 0 ? '未发货' : (state === 2 ? '已发货' : '已收货') }}
            </template>
          </el-table-column>
          <el-table-column label="维权状态">
            <template slot-scope="scope">
              {{ refund.refund_status_text }}
            </template>
          </el-table-column>
        </el-table>
      </template>
      <br />
      <h4>包裹信息</h4>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="包裹1" v-if="expressInfo.length !== 0" name="1">
          <p style="background-color: #6666; height: 30px; line-height: 30px;">
            <span>快递公司: {{ expressInfo[0].logiName }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ expressInfo[0].deliveryNo }}</span>
          </p>
          <!-- 普通物流 -->
          <div class="logistics-info" v-if="expressInfo[0]['code']">
            <p v-if="!expressInfo[0].data" style="display:block; line-height: 20px;">{{ expressInfo[0].message }}</p>
            <ul v-else>
              <li v-for="(ship, index) in expressInfo[0].data.orderTrack" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.content }}</p>
                  <p class="ship-msg-time">{{ ship.msgTime }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 京东物流 -->
          <div class="logistics-info" v-else>
            <p v-if="!expressInfo[0].data" style="display:block; line-height: 20px;">{{ expressInfo[0].message }}</p>
            <ul v-else>
              <li v-for="(ship, index) in expressInfo[0].data[0].express.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="售后信息" name="2">
          <table>
            <tr>
              <td>售后单号：</td>
              <td>{{ refund.sn }}</td>
            </tr>
            <tr>
              <td>关联订单：</td>
              <td>{{ refund.trade_sn }}</td>
            </tr>
            <tr>
              <td>申请时间：</td>
              <td>{{ refund.create_time | unixToDate }}</td>
            </tr>
            <tr>
              <td>售后状态：</td>
              <td>{{ refund.refund_status_text }}</td>
            </tr>
            <tr>
              <td>服务类型：</td>
              <td>{{ refund.refuse_type_text }}</td>
            </tr>
            <tr>
              <td>申请原因：</td>
              <td>{{ refund.refund_reason }}</td>
            </tr>
            <tr>
              <td>问题描述：</td>
              <td>{{ refund.customer_remark ? refund.customer_remark : '' }}</td>
            </tr>
            <tr v-if="imgList.length !== 0">
              <td style="padding-bottom: 55px;">图片凭证：</td>
              <td>
                <div style="display: flex;">
                  <div v-for="(item, index) in imgList" :key="index">
                    <el-image v-if="item.indexOf('.mp4') == -1" style="width: 80px; height: 80px" :src="item"
                      :preview-src-list="imgList">
                    </el-image>
                    <div class="playVideo" @click="playVideo(item)" v-else>
                      <img :src="item + '?x-oss-process=video/snapshot,t_10000,m_fast'" alt="">
                      <span class="el-icon-video-play"></span>
                    </div>
                  </div>
                  <!-- <img :src="i" v-for="(i, index) in imgList" :key="index" style="width: 80px; height: 80px;" alt="" /> -->
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>图片凭证：</td>
              <td>无</td>
            </tr>
            <tr>
              <td>{{ orderDetail.mall_type == 7 ? '商城退款方式：' : '用户退款方式' }}：</td>
              <td>{{ orderDetail.mall_type == 7 ? '退回站点账户余额' : '退回余额' }}</td>
            </tr>
            <tr
              v-if="refund.refund_status !== 'APPLY' && refund.refund_status !== 'REFUSE' && refund.refuse_type_text !== '售后补发'">
              <td>是否已寄回：</td>
              <td v-if="refundExpressInfo">是</td>
              <td v-else>否</td>
            </tr>
            <tr>
              <td>退款金额：</td>
              <td>{{ refund.refund_supplier_price }}</td>
            </tr>
            <tr v-if="refund.refund_reason === '线下售后'">
              <td>物流信息</td>
              <td>
                当前商品会退回到京东指定的退货地址。详细物流信息请通过您邮寄时的物流单号进行查询。
              </td>
            </tr>
          </table>
        </el-tab-pane>
      </el-tabs>
      <div style="display: flex;" v-if="refundExpressInfo">
        <div style="margin-right: 20px;">
          <h4>退/换货商品物流信息：</h4>
          <p class="new_logistics">
            <span>快递公司: {{ refundExpressInfo.name }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ refundExpressInfo.courier_num }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>收货人手机号: {{ refund.customer_ship_mobile }}</span>
          </p>
          <div class="logistics-info">
            <p v-if="!refundExpressInfo.data" style="display:block; line-height: 20px;">
              {{ refundExpressInfo.message }}
            </p>
            <ul v-else>
              <li v-for="(ship, index) in refundExpressInfo.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="refund.refuse_type_text !== '退款退货' && refundExpressInfo1">
          <h4>新发货商品物流信息：</h4>
          <p class="new_logistics">
            <span>快递公司: {{ refundExpressInfo1.name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ refundExpressInfo1.courier_num }}</span>
          </p>
          <div class="logistics-info">
            <p v-if="!refundExpressInfo1.data" style="display:block; line-height: 20px;">
              {{ refundExpressInfo1.message }}
            </p>
            <ul v-else>
              <li v-for="(ship, index) in refundExpressInfo1.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="视频" :visible.sync="videoding" width="80%">
      <video v-if="videoding" style="width:100%;height:500px" controls>
        <source :src="videoUrl" type="video/mp4" />
        <source :src="videoUrl" type="video/webm" />
      </video>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Refund from '@/api/refund'

export default {
  name: 'refundDetail',
  data () {
    return {
      //视频播放弹窗
      videoUrl: '',
      videoding: false,
      loading: false,
      refund: '',
      sn: this.$route.params.sn,
      tableData: [],
      orderDetail: {},
      state: '',
      activeName: '1',
      imgList: [],
      expressInfo: [],
      refundExpressInfo1: null,
      refundExpressInfo: null,
      allData: {}
    }
  },
  mounted () {
    this.GET_RefundDetail();
  },
  methods: {
    /**视频播放弹窗 */
    playVideo (src) {
      this.videoUrl = src;
      this.videoding = true;
    },
    /** 获取售后订单详情 */
    GET_RefundDetail () {
      this.loading = true
      API_Refund.getRefundDetail(this.sn).then(res => {
        this.loading = false
        this.refund = res.refund
        this.allData = res
        this.tableData = res.refund_goods
        this.orderDetail = res.trade_do
        this.state = res.state
        if (this.refund.refund_img) {
          this.imgList = this.refund.refund_img.split(',')
        }
        if (res.delivery_json) {
          this.expressInfo = JSON.parse(res.delivery_json)
        } else {
          this.activeName = '2'
        }
        if (this.expressInfo) {
          if (!this.expressInfo[0].logiId) {
            this.expressInfo[0].logiId = 12
          }
        }
        let params = {}
        params['order_sn'] = this.refund.order_sn
        params['sku_id'] = this.tableData[0].sku_id
        params['ext_goods_id'] = this.tableData[0].ext_id
        params['is_combo'] = 0
        if (this.expressInfo) {
          API_Refund.getExpressListBySkuIdNew(params).then(res => {
            if (res.code === 1) {
              this.expressInfo[0]['data'] = res.data
              this.expressInfo[0]['data']['orderTrack'].reverse()
              this.expressInfo[0]['code'] = 1
            } else {
              this.expressInfo[0]['data'] = res.data
              this.expressInfo[0]['message'] = res.message
            }
            this.$forceUpdate()
          }).then(() => {
            // 供应商物流信息
            if (this.refund.seller_logi_id && this.refund.seller_ship_no) {
              API_Refund.getLogisticsInfo({
                id: this.refund.seller_logi_id,
                num: this.refund.seller_ship_no,
                mobile: this.orderDetail.consignee_mobile
              }).then(res => {
                this.refundExpressInfo1 = res
              })
            }
            // 买家回寄物流信息
            if (this.refund.customer_logi_id && this.refund.customer_ship_no) {
              API_Refund.getLogisticsInfo({
                id: this.refund.customer_logi_id,
                num: this.refund.customer_ship_no,
                mobile: this.orderDetail.consignee_mobile
              }).then(res => {
                this.refundExpressInfo = res
              })
            }
          })
        }
      }).catch(() => { this.loading = false })
    },
    trade_status (status) {
      switch (status) {
        case 'NEW':
          return '新订单'
        case 'CONFIRM':
          return '待付款'
        case 'PAID_OFF':
          return '待发货'
        case 'SHIPPED':
          return '已发货'
        case 'ROG':
          return '已收货'
        case 'COMPLETED':
          return '已完成'
        case 'COMPLETE':
          return '已完成'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.refund-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.back_btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #1A43A9;
  float: right;

  img {
    width: 10px;
    height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.goods-image {
  width: 55px;
  height: 55px;
  margin-right: 5px;
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.playVideo {
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: relative;

  img {
    width: 80px;
    height: 80px;
  }

  span {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 20px;
  }
}

.new_logistics {
  background-color: #6666;
  height: 30px;
  line-height: 30px;
  padding: 0 12px
}
</style>
